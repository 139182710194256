
header {
    height: 120vh;
    padding-top: 1rem;
    overflow: hidden;
}
.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}
.furqan-img{
    height:100%;
                width:100%;
                object-fit: cover;
}
/*========CTA=====*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
/*========Header Socials=====*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
/*========Me=====*/
.me{
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}
/*========Scroll Down========*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
/* ===========Media Queries(Medium devices)============ */
@media screen and (max-width: 1024px) {
    .header{
      height: 68vh;
    }
  }
  /* ===========Media Queries(Small devices)============ */
  @media screen and (max-width: 600px) {
    .header{
        height: 100vh;
      }
      .header__socials, .scroll__down{
        display: none;
      }
  }